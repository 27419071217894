<template>
  <div id="ConciliacaoBancaria">
    <v-toolbar-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" >
      <!-- TITULO DA PAGINA -->
      <v-toolbar-title class="text-white">
        <router-link :to="{ name: 'EmpreendimentoConsulta' }" class="nav-link" aria-current="page">
          <v-btn icon dark color="grey" class="btn-icon mr-1">
            <v-icon color="#84A0CD" class="icon-action"> mdi-chevron-left </v-icon>
          </v-btn>
        </router-link>
        Conciliação Bancária
      </v-toolbar-title>
      <!-- FIM TITULO DA PAGINA -->
    </v-toolbar-title>

    <v-container class="pa-0">
      <!-- cabecalho consulta -->
      <div class="cabecalho d-flex" style="width:100%; max-height: 70px!important;">
        <v-col cols="12" sm="2">
          <v-select
            v-model="banco_selecionado"
            :items="opcoes_banco"
            item-text="banco_nome"
            item-value="cod_banco"
            label="Banco"
            dense
            flat
            outlined
            return-object
            :tabindex="1"
            >
          </v-select>
        </v-col>
        <!-- <pre>{{ cedente_selecionado }}</pre> -->
        <v-col cols="12" sm="7">
          <v-select
            v-model="cedente_selecionado"
            :items="opcoes_cedente"
            item-text="pessoa_conta_descricao"
            item-value="cod_pessoa_conta"
            label="Cliente / Conta"
            dense
            flat
            outlined
            return-object
            :loading="loading_cedente"
            :tabindex="2">
          </v-select>
        </v-col>
        <v-col cols="12" sm="3" align-self="center">
          <span>Saldo Atual: </span>
          <span :style="saldo_atual_calculado < 0
                        ? {'color': 'red', 'font-weight': '600'}
                        : {'color': 'green', 'font-weight': '600'}">
            R$ {{ saldo_atual_calculado ? formatPrice(saldo_atual_calculado) : 0 }}
          </span>
        </v-col>
      </div>
      <v-divider class="divisor-horizontal"></v-divider>

      <v-card elevation="0" class="corpo-fundo-cor card d-flex d-flex-column">

        <!-- COLUNA ESQUERDA ------------------------------->
        <v-card elevation="0" v-show="expand" style="background-color: transparent;">

          <!-- Filtro por Período ------------ -->
          <v-card elevation="0" class="pa-3" style="background-color: transparent;">

            <div class="mb-2 d-flex justify-center">
              <v-btn
                v-if="cedente_selecionado"
                color="primary"
                class="text-none ml-6"
                depressed
                @click="getFile"
              >
                <v-icon left>
                  mdi-upload
                </v-icon>
                Importar OFX
              </v-btn>
              <v-file-input v-model="arquivo" hide-input prepend-icon="" ref="fileInput"/>
  
            </div>

            <!-- SEPARADOR ------------------------------->
            <v-divider class="my-1"></v-divider>

            <div class="mb-2 d-flex justify-center">
              <span class="font-weight-bold" v-bind:style="{ 'font-size': '15px', 'color':COR_SUBTITULO }">Filtro por Período</span>
            </div>
            <v-menu
                v-model="menu1"
                :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedDate1FormattedMomentjs"
                  label="Inicial"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  flat
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date1"
                @change="menu1 = false; if (date1Aux != date1) { itemSelecionado = null; date1Aux = date1; busca_extratos(cedente_selecionado.cod_pessoa_conta, formatDate(date1), formatDate(date2))};"
              ></v-date-picker>
            </v-menu>
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedDate2FormattedMomentjs"
                  label="Final"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  flat
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date2"
                @change="menu2 = false; if (date2Aux != date2) { itemSelecionado = null; date2Aux = date2; busca_extratos(cedente_selecionado.cod_pessoa_conta, formatDate(date1), formatDate(date2))};"
              ></v-date-picker>
            </v-menu>

            <!-- SEPARADOR ------------------------------->
            <v-divider class="my-1"></v-divider>
          </v-card>

          <!-- Filtro por Dias do Extrato ------------ -->
          <div class="mb-2 d-flex justify-center">
            <span
              class="font-weight-bold"
              :style="{ 'font-size': '15px', 'color':COR_SUBTITULO }">
              Filtro por Dia
            </span>
          </div>
          <v-card elevation="0" class="d-flex flex-column pb-0" style="background-color: transparent;">
            <v-list class="card-list overflow-y-scroll pt-0" dense style="background-color: transparent;">

                <!-- DADOS ------------------------------->
                <v-list-item-group
                  v-model="itemSelecionado"
                  active-class="bg-active"
                  color="primary"
                  >
                <template v-for="i in movimentos_dia">
                  <v-list-item
                    :key="i.data"
                    @change="busca_extratos(cedente_selecionado.cod_pessoa_conta,
                                                                i.data,
                                                                i.data)"
                  >
                  <!-- @click="importarPlanilha = false" -->
                    <v-list-item-content  class="">
                      <v-badge
                        v-if="i.qtde_movimentos == i.qtde_conciliados"
                        :value="i.qtde_movimentos"
                        color="green accent-9"
                        offset-x="35"
                        offset-y="18"
                        icon="mdi-check">
                      </v-badge>
                      <v-badge
                        v-else
                        :content="i.qtde_movimentos - i.qtde_conciliados"
                        :value="i.qtde_movimentos - i.qtde_conciliados"
                        color="red accent-1"
                        offset-x="35"
                        offset-y="18">
                      </v-badge>
                      <v-list-item-title class="new-font">{{ i.data }}</v-list-item-title>
                    </v-list-item-content>

                  </v-list-item>
                </template>

              </v-list-item-group>
            </v-list>

          </v-card>

        </v-card>
        <!-- FIM COLUNA ESQUERDA ------------------------------->

            <!-- divisor e botao de expand -->
            <v-card>
              <v-divider class="divisor-vertical" vertical></v-divider>
              <v-btn
                class="btn-expanded ma-0 pa-0"
                :style="`${expand ? '' : 'right: -10px!important;'}`"
                @click="expand = !expand">
                <v-icon
                  v-if="expand == false"
                  class="size-icon-expanded ma-0 pa-0">mdi-chevron-right
                </v-icon>

                <v-icon
                  v-else
                  class="size-icon-expanded ma-0 pa-0">mdi-chevron-left
                </v-icon>
              </v-btn>
            </v-card>

            <!-- COLUNA DIREITA ------------------------------->
            <div class="pa-4" style="width: 100%; background-color: #FFFFFF;">
              <!-- INICIO TITULOS TABELA EXTRATO -------------------------------------------------------->
              <table style="width: 100%; background-color: transparent;">
                <tr class="d-flex" style="border-bottom: 1px solid #E0E0E0">
                  <td class="d-flex justify-center" style="width: 50%!important; background-color: transparent;">
                    <span
                      class="font-weight-bold"
                      v-bind:style="{ 'font-size': '15px', 'color':COR_SUBTITULO }">(Banco)
                      Extrato
                      Importado
                      ({{ formatDate(date1)}}
                      -
                      {{ formatDate(date2) }})</span>
                  </td>
                  <!-- <td class="ml-n7 d-flex justify-end" style="width: 250px!important; background-color: transparent;">
                    <span class="d-flex justify-end" v-bind:style="{ 'font-size': '17px', 'font-weight': '600', 'color': 'green' }"><strong>R$ {{ Number(dados.saldo_banco) > 0 ? formatPrice(dados.saldo_banco) : formatPrice(Number(dados.saldo_banco)*-1) }}</strong></span>
                  </td>
                  <td class="d-flex justify-center" style="width: 130px!important; background-color: transparent;">
                  </td>
                  <td class="d-flex justify-start" style="width: 250px!important; background-color: transparent;">
                    <span class="" v-bind:style="{ 'font-size': '17px', 'font-weight': '600', 'color': 'green' }"><strong>R$ {{ Number('3163944.90') > 0 ? formatPrice('3163944.90') : formatPrice(Number('3163944.90')*-1) }}</strong></span>
                  </td> -->
                  <!-- <td class="d-flex justify-center" style="width: 130px!important; background-color: transparent;">
                  </td> -->
                  <td class="d-flex justify-space-between" style="width: 50%!important; background-color: transparent;">
                    <div class="ml-9" style="width: 50px!!important">
                      <!-- <v-tooltip top>
                        <span class="text-none">Adicionar sugestão automática para todos os movimentos abertos </span>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" color="#E0E0E0" class="outlined-btn background-hover ml-9 mt-n4" width="40px" height="40px" dense fab elevation="0" outlined>
                            <v-icon color="blue lighten-1">mdi-auto-fix</v-icon>
                            <span class="text-none"></span>
                          </v-btn>
                        </template>
                      </v-tooltip> -->
                    </div>
                    <div class="d-flex justify-center" style="width: 100%!important; background-color: transparent;">
                      <span class="ml-n10 font-weight-bold" v-bind:style="{ 'font-size': '15px', 'color':COR_SUBTITULO }">(Sistema) Meus Movimentos</span>
                    </div>
                  </td>
                </tr>
              </table>
              <!-- FIM TITULOS TABELA EXTRATO -------------------------------------------------------->
              <!-- {{ dialog_lancamentos }} -->
              <!-- INICIO TABELA EXTRATO -------------------------------------------------------->
              <v-data-table
                :items="dados"
                :loading="loading"
                :items-per-page="1000"
                dense
                style="white-space: nowrap"
                hide-default-header
                hide-default-footer
                loading-text="Carregando...  aguarde..."
                no-data-text="Nenhum Registro Encontrado"
                no-results-text="Nenhum Registro Encontrado"
                class="scroll-direita">
                <!-- :height="tableHeight" -->

                <template #item="{ item }">
                  <tr :class="Number(item.valor) < 0 ? 'custom-highlight-row' : ''">
                    <!-- Extrato -->
                    <td style="width: 50%;">
                      <v-card elevation="0" style="background-color: transparent">
                          <v-row>
                            <v-col class="mt-3 d-flex justify-end">
                              <div style="min-height: 30px!important;">
                                <span v-bind:style="{ 'font-size': '13px'}">{{ item?.descricao }}</span>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="mb-n4 mt-n4 d-flex justify-end">
                              <div>
                                <span v-bind:style="{ 'font-size': '14px', 'font-weight': '600'}">
                                  <v-icon class="mt-n1" color="#A7A9AA">mdi-calendar</v-icon>
                                  {{ formatDate(item.data) }}
                                </span>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="mb-3 d-flex justify-end">
                              <div>
                                <span
                                  :style="{ 'font-size': '17px', 'font-weight': '600', 'color': item.valor > 0 ? 'green' : item.valor < 0 ? 'red' : 'gray' }">
                                  <strong>
                                    R$ {{ Number(item.valor) > 0 ? formatPrice(item.valor) : formatPrice(item.valor*-1) }}
                                  </strong>
                                </span>
                              </div>
                            </v-col>
                          </v-row>
                      </v-card>
                    </td>

                    <!-- Coluna do Meio (Separador) -->
                    <td :style="item.extrato_mov_conc.length <= 0 && !item.extrato_mov_transf && item.conciliado != 'DES'
                        ? {width: '100px!important', 'background-color': '#FFD9E9', 'border-left': '1px solid #E0E0E0', 'border-right': '1px solid #E0E0E0'}
                        : {width: '100px!important', 'background-color': '#D0FFE0', 'border-left': '1px solid #E0E0E0', 'border-right': '1px solid #E0E0E0'}"
                    >
                      <div v-if="item.conciliado == 'SIM' || !!item.extrato_mov_transf || item.conciliado == 'DES'">
                        <v-icon x-large outlined class="green--text lighten-1"> mdi-check-bold </v-icon>
                      </div>
                      <div v-else>
                        <v-icon x-large outlined class="red--text lighten-1"> mdi-arrow-right-bold </v-icon>
                      </div>
                    </td>

                    <!-- Cobrancas -->
                    <td v-if="item.conciliado == 'SIM' && item.extrato_mov_conc.length >= 1" style="width: 50%; background-color: transparent">
                      <v-card elevation="0" style="background-color: transparent">
                          <v-row>
                            <v-col class="mt-3 d-flex justify-start">
                              <div>
                                <span
                                  v-if="item.extrato_mov_conc.length == 1"
                                  :style="{ 'font-size': '13px'}"
                                  @click="dialog_lancamentos = null; dialog_lancamentos = JSON.parse(JSON.stringify(item))">
                                  <a
                                  :style="{ 'font-size': '12px', 'text-decoration': 'underline'}">
                                    <h3>
                                      {{item.extrato_mov_conc[0].empreendcobr_descricao}}
                                    </h3>
                                  </a>
                                </span>

                                <span
                                  v-if="item.extrato_mov_conc.length > 1"
                                  :style="{ 'font-size': '13px'}"
                                  @click="dialog_lancamentos = null; dialog_lancamentos = JSON.parse(JSON.stringify(item))"><a
                                  :style="{ 'font-size': '13px', 'text-decoration': 'underline'}"><h3>{{item.extrato_mov_conc.length}}
                                  lançamentos</h3></a></span>
                              </div>
                            </v-col>
                            <v-col cols="auto" class="mt-1 d-flex justify-end" style="width: 60px!important;">
                              <v-tooltip top>
                                <span class="text-none">Clique para desconciliar</span>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    @click="dialog_estornar = item.cod_extrato_movimento"
                                    style="background-color: #FFFFFF;"
                                    color="#E9E9E9"
                                    class="background-hover mr-n3"
                                    width="40px"
                                    height="40px"
                                    dense
                                    fab
                                    elevation="0"
                                    outlined>
                                    <v-icon color="red lighten-1" dense dark >mdi-lock-open-variant-outline</v-icon>
                                  </v-btn>
                                </template>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="mb-n4 mt-n4 d-flex justify-start">
                              <div>
                                <span v-bind:style="{ 'font-size': '14px', 'font-weight': '600'}">
                                  <v-icon class="mt-n1" color="#A7A9AA">mdi-calendar</v-icon>
                                  {{ formatDate(item.data) }}
                                </span>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="mb-3 d-flex justify-start">
                              <div>
                                <span :style="{ 'font-size': '17px', 'font-weight': '600', 'color': item.valor > 0 ? 'green' : item.valor < 0 ? 'red' : 'gray' }"><strong>R$ {{ Number(item.valor) > 0 ? formatPrice(item.valor) : formatPrice(item.valor*-1) }}</strong></span>
                              </div>
                            </v-col>
                          </v-row>
                      </v-card>
                    </td>

                    <!-- Transferencia -->
                    <td v-if="!!item.extrato_mov_transf" style="width: 50%; background-color: transparent">
                      <v-card elevation="0" style="background-color: transparent">
                          <v-row>
                            <v-col class="mt-3 d-flex justify-start">
                              <div>
                                <strong>De: </strong>
                                <span>
                                  {{item.extrato_mov_transf.pessoa_nome_origem}}
                                </span>
                                <br>
                                <strong>Para: </strong>
                                <span>{{item.extrato_mov_transf.pessoa_nome_destino}}</span>
                              </div>
                            </v-col>
                            <v-col cols="auto" class="mt-1 d-flex justify-end" style="width: 60px!important;">
                              <v-tooltip top>
                                <span class="text-none">Clique para estornar esta conciliação</span>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    @click="dialog_estornar_transferencia = item"
                                    style="background-color: #FFFFFF;"
                                    color="#E9E9E9"
                                    class="background-hover mr-n3"
                                    width="40px"
                                    height="40px"
                                    dense
                                    fab
                                    elevation="0"
                                    outlined>
                                    <v-icon color="red lighten-1" dense dark >mdi-lock-open-variant-outline</v-icon>
                                  </v-btn>
                                </template>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="mb-n4 mt-n4 d-flex justify-start">
                              <div>
                                <span v-bind:style="{ 'font-size': '14px', 'font-weight': '600'}">
                                  <v-icon class="mt-n1" color="#A7A9AA">mdi-calendar</v-icon>
                                  {{ formatDate(item.extrato_mov_transf.cadastro_dt) }}
                                </span>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="mb-3 d-flex justify-start">
                              <div>
                                <span
                                  :style="{ 'font-size': '17px', 'font-weight': '600', 'color': item.extrato_mov_transf.debito_credito === 'C' ? 'green' : item.extrato_mov_transf.debito_credito === 'D' ? 'red' : 'gray' }">
                                  <strong>
                                    R$ {{ Number(item.extrato_mov_transf.valor) > 0 ? formatPrice(item.extrato_mov_transf.valor) : formatPrice(item.extrato_mov_transf.valor*-1)}}
                                  </strong>
                                  <!-- <pre>{{ item.extrato_mov_transf }}</pre> -->
                                </span>
                              </div>
                            </v-col>
                          </v-row>
                      </v-card>
                    </td>

                    <!-- Conciliação Desconsiderada -->
                    <td v-if="item.conciliado == 'DES'" style="width: 50%; background-color: transparent">
                      <v-card elevation="0" style="background-color: transparent">
                          <v-row>
                            <v-col class="mt-3 d-flex justify-start">
                              <div>
                                <span
                                  :style="{ 'font-size': '17px', 'font-weight': '600', 'color': 'gray' }">
                                  <strong>Conciliação Desconsiderada </strong>
                                </span><br>
                                <span
                                  :style="{ 'font-size': '14px', 'font-weight': '500', 'color': 'gray' }">
                                  {{item.desconsideracao_motivo}}
                                </span>                                
                              </div>
                            </v-col>
                            <v-col cols="auto" class="mt-1 d-flex justify-end" style="width: 60px!important;">
                              <v-tooltip top>
                                <span class="text-none">Clique para desconciliar</span>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    @click="dialog_estornar = item.cod_extrato_movimento"
                                    style="background-color: #FFFFFF;"
                                    color="#E9E9E9"
                                    class="background-hover mr-n3"
                                    width="40px"
                                    height="40px"
                                    dense
                                    fab
                                    elevation="0"
                                    outlined>
                                    <v-icon color="red lighten-1" dense dark >mdi-lock-open-variant-outline</v-icon>
                                  </v-btn>
                                </template>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                          <!-- <v-row>
                            <v-col class="mb-n4 mt-n4 d-flex justify-start">
                              <div>
                                <span v-bind:style="{ 'font-size': '14px', 'font-weight': '600'}">
                                  <v-icon class="mt-n1" color="#A7A9AA">mdi-calendar</v-icon>
                                  {{ formatDate(item.) }}
                                </span>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="mb-3 d-flex justify-start">
                              <div>
                                <span
                                  :style="{ 'font-size': '17px', 'font-weight': '600', 'color': item.extrato_mov_transf.debito_credito === 'C' ? 'green' : item.extrato_mov_transf.debito_credito === 'D' ? 'red' : 'gray' }">
                                  <strong>
                                    R$ {{ Number(item.extrato_mov_transf.valor) > 0 ? formatPrice(item.extrato_mov_transf.valor) : formatPrice(item.extrato_mov_transf.valor*-1)}}
                                  </strong>
                                </span>
                              </div>
                            </v-col>
                          </v-row> -->
                      </v-card>
                    </td>

                    <!-- Botoes de Acao -->
                    <td v-if="item.conciliado == 'NÃO' && !item.extrato_mov_conc.length && !item.extrato_mov_transf" style="width: 50%; background-color: transparent">
                      <v-card elevation="0" style="background-color: transparent">
                        <v-card-text style="background-color: transparent">
                          <!-- <v-tooltip top>
                            <span class="text-none">Sugestão de lançamento para o movimento do extrato ao lado</span>
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on" color="#E9E9E9" style="background-color: #FFFFFF;" class="background-hover mr-4" width="40px" height="40px" dense fab elevation="0" outlined>
                                <v-icon color="blue lighten-1">mdi-auto-fix</v-icon>
                              </v-btn>
                            </template>
                          </v-tooltip> -->
                          <v-tooltip top>
                            <span class="text-none">Incluir lançamento de receita</span>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-if="item.valor > 0"
                                @click="dialog_lancamentos = null; dialog_lancamentos = JSON.parse(JSON.stringify(item))"
                                v-on="on"
                                style="background-color: #FFFFFF;"
                                color="#E9E9E9"
                                class="background-hover mr-4"
                                width="40px"
                                height="40px"
                                dense
                                fab
                                elevation="0"
                                outlined>
                                <v-icon color="green lighten-1">mdi-cash-plus</v-icon>
                              </v-btn>
                            </template>
                          </v-tooltip>
                          <v-tooltip top>
                            <span class="text-none">Incluir lançamento de despesa</span>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-if="item.valor < 0"
                                @click="dialog_lancamentos = null; dialog_lancamentos = JSON.parse(JSON.stringify(item));"
                                v-on="on"
                                style="background-color: #FFFFFF;"
                                color="#E9E9E9"
                                class="background-hover mr-4"
                                width="40px"
                                height="40px"
                                dense
                                fab
                                elevation="0"
                                outlined>
                                <v-icon color="red lighten-1">mdi-cash-minus</v-icon>
                              </v-btn>
                            </template>
                          </v-tooltip>
                          <!-- <v-tooltip top>
                            <span class="text-none">Localizar um lançamento existente</span>
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on" color="#E9E9E9" style="background-color: #FFFFFF;" class="background-hover mr-4" width="40px" height="40px" dense fab elevation="0" outlined>
                                <v-icon color="#909090">mdi-magnify-plus-outline</v-icon>
                              </v-btn>
                            </template>
                          </v-tooltip> -->
                          <v-tooltip
                            top>
                            <span class="text-none">Cadastrar nova transferência</span>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                @click="abrirModalTransferencia(item)"
                                v-on="on"
                                color="#E9E9E9"
                                style="background-color: #FFFFFF;"
                                class="background-hover mr-4"
                                width="40px"
                                height="40px"
                                dense
                                fab
                                elevation="0"
                                outlined>
                                <v-icon color="#909090">mdi-swap-horizontal</v-icon>
                              </v-btn>
                            </template>
                          </v-tooltip>
                          <v-tooltip
                            top>
                            <span class="text-none">Desconsiderar esta conciliação</span>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                @click="motivo_DesconsiderarConciliacao = null; dialog_DesconsiderarConciliacao = item"
                                v-on="on"
                                color="#E9E9E9"
                                style="background-color: #FFFFFF;"
                                class="background-hover mr-4"
                                width="40px"
                                height="40px"
                                dense
                                fab
                                elevation="0"
                                outlined>
                                <v-icon color="orange">mdi-link-lock</v-icon>
                              </v-btn>
                            </template>
                          </v-tooltip>
                          
                        </v-card-text>
                      </v-card>
                    </td>
                  </tr>

                </template>
              </v-data-table>
              <!-- FIM TABELA EXTRATO ----------------->

              <!-- RODAPE TABELA EXTRATO ----------->
              <table style="width: 100%; background-color: transparent">
                <tr class="d-flex pb-4" style="border-top: 1px solid #E0E0E0">
                  <td class="d-flex justify-center" style="width: 50%!important; background-color: transparent;">
                    <!-- <span class="font-weight-bold" v-bind:style="{ 'font-size': '15px', 'color':COR_SUBTITULO }">Extrato Bancário</span> -->
                  </td>
                  <td
                    class="ml-n7 d-flex justify-end"
                    :style="{'width': '250px!important', 'background-color': dados.falta_conciliar <= 0 ? 'green' : '#FF0000'}">
                    <span
                      class="d-flex justify-end"
                      v-bind:style="{ 'font-size': '18px', 'font-weight': '600', 'color': '#FFFFFF' }">
                      <strong>
                        R$ {{ Number(dados.valor_total_extrato) > 0 ? formatPrice(dados.valor_total_extrato): formatPrice(Number(dados.valor_total_extrato)*-1)  }}
                      </strong>
                    </span>
                  </td>
                  <td
                    class="d-flex justify-center"
                    :style="{'width': '130px!important', 'background-color': dados.falta_conciliar <= 0 ? 'green' : '#FF0000'}">
                  </td>
                  <td
                    class="d-flex justify-start"
                    :style="{'width': '250px!important', 'background-color': dados.falta_conciliar <= 0 ? 'green' : '#FF0000'}">
                    <span
                      class="d-flex justify-end"
                      v-bind:style="{ 'font-size': '18px', 'font-weight': '600', 'color': '#FFFFFF' }">
                      <strong>
                        R$ {{ Number(dados.valor_total_sistema) > 0 ? formatPrice(dados.valor_total_sistema) : formatPrice(Number(dados.valor_total_sistema)*-1) }}
                      </strong>
                    </span>
                  </td>
                  <td
                    class="d-flex justify-center"
                    style="width: 50%!important; background-color: transparent;">
                    <!-- <span class="font-weight-bold" v-bind:style="{ 'font-size': '15px', 'color':COR_SUBTITULO }">Meus Movimentos</span> -->
                  </td>
                </tr>
              </table>
            <!-- FIM RODAPE TABELA EXTRATO --------------->

          </div>
          <!-- FIM COLUNA DIREITA ------------------------------->

      </v-card>
    </v-container>

    <!-- Dialogo para visualizar os lançamentos desta conciliação -->
    <div v-if="dialog_lancamentos">
      <v-dialog
        style="height: 30vh; overflow: hidden"
        :value="dialog_lancamentos"
        scrollable
        persistent
        class="mx-10"
        max-height="90%"
        min-height="200px"
        max-width="1200px">
        <ConciliacaoBancariaLancamentos
          :dialog_lancamentos="dialog_lancamentos"
          :cedente_selecionado="cedente_selecionado"
          @close="dialog_lancamentos = null"
          @atualiza_extrato="atualiza_extrato"
       />

      </v-dialog>
    </div>
    <!-- FIM Dialog -->

    <!-- Inicio Dialogo Estornar -->
    <div v-if="dialog_estornar ? true : false">
      <v-dialog
        :value="dialog_estornar"
        transition="dialog-bottom-transition"
        persistent
        max-width="410"
        class="pa-0">
        <v-divider></v-divider>
        <v-card elevation="0" class="">
          <v-card-title class="text-h6">
            Tem certeza que deseja ESTORNAR esta conciliação?
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-4"
              color="primary"
              text
              @click="dialog_estornar = false">
              Cancelar
            </v-btn>
            <v-btn
              class="btn white--text"
              color="primary accent-4"
              :loading="loading"
              @click="estornar(dialog_estornar)">
              Estornar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- Fim Dialogo Estornar -->

     <!-- Inicio Dialogo Estornar -->
     <div v-if="dialog_estornar_transferencia ? true : false">
      <v-dialog
        :value="dialog_estornar_transferencia"
        transition="dialog-bottom-transition"
        persistent
        max-width="410"
        class="pa-0">
        <v-divider></v-divider>
        <v-card elevation="0" class="">
          <v-card-title class="text-h6">
            Tem certeza que deseja ESTORNAR esta transferência?
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-4"
              color="primary"
              text
              @click="dialog_estornar_transferencia = false">
              Cancelar
            </v-btn>
            <v-btn
              class="btn white--text"
              color="primary accent-4"
              :loading="loading"
              @click="estornar_transferencia(dialog_estornar_transferencia)">
              Estornar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- Fim Dialogo Estornar -->

    <!-- Dialogo de Transferencia -->
    <v-container
      v-if="dialog_TransferenciaModal">
      <v-dialog
        v-model="dialog_TransferenciaModal"
        transition="dialog-bottom-transition"
        persistent
        max-width="500">
        <TransferenciaModal
          :dialog_TransferenciaModal.sync="dialog_TransferenciaModal"
          :obj_extrato_movimento="extrato_movimento_aux"
          :cedente_selecionado="cedente_selecionado"
          @limparCodExtratoMoimento="limparCodExtratoMoimento"
          @atualiza_extrato="atualiza_extrato"/>
      </v-dialog>
    </v-container>

    <!-- Dialogo de Desconsiderar -->
    <v-container
      v-if="dialog_DesconsiderarConciliacao"
      max-height="300">
      <v-dialog v-model="dialog_DesconsiderarConciliacao"
                transition="dialog-bottom-transition"
                max-width="600"
                max-height="300"
                class="pa-0">
        <v-card elevation="0">
          <!-- Cabeçalho -->
          <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
                    class="justify-space-between py-1 px-4">
              <!--Cabeçalho padrão para ações de pagar e de Edição -->
              <div>
                <span class="title-page">Desconsiderar conciliação</span>
              </div>
            <v-btn @click="dialog_DesconsiderarConciliacao = null" icon dark color="#F2F6F7">
              <v-icon large class="title">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-title class="text-h6">
            Deseja realmente desconsiderar esta conciliação?
          </v-card-title>
          <v-card-text>
              <v-form ref="form" v-model="form">
                <v-row>
                  <v-col class="pb-0" cols="12">
                    <v-textarea 
                      rows="2" 
                      auto-grow 
                      counter="300" 
                      v-model="motivo_DesconsiderarConciliacao" 
                      class="input-razao mb-0" 
                      loader-height="2" 
                      autofocus
                      background-color="#FFF" 
                      label="Motivo" 
                      placeholder="Motivo" 
                      light 
                      filled 
                      outlined 
                      required 
                      clearable
                      dense></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
          </v-card-text>
          <v-footer color="#F2F6F7" elevation="0" class="d-flex justify-center pa-0">
            <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
              <v-btn
              @click="dialog_DesconsiderarConciliacao = null"
                class="mr-4 btn text-none"
                color="primary"
                text
              >
                Cancelar
              </v-btn>
              <v-btn
              @click="desconsiderarConciliacao(dialog_DesconsiderarConciliacao)"
                class="mr-4 btn text-none"
                color="primary"
                elevation="0"
                :disabled="!form"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-footer>

          <!-- <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialog_DesconsiderarConciliacao = null">
              Cancelar
            </v-btn>
            <v-btn class="btn white--text" color="primary accent-4" :disabled="!form" :loading="loading" @click="desconsiderarConciliacao(dialog_DesconsiderarConciliacao)">
              Salvar
            </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-dialog>
    </v-container>

     <!-- Inicio Dialogo Estornar Desconsideração Conciliação -->
     <div v-if="dialog_estornar_desconsideracao ? true : false">
      <v-dialog
        :value="dialog_estornar_desconsideracao"
        transition="dialog-bottom-transition"
        persistent
        max-width="410"
        class="pa-0">
        <v-divider></v-divider>
        <v-card elevation="0" class="">
          <v-card-title class="text-h6">
            Tem certeza que deseja ESTORNAR esta Conciliação?
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-4"
              color="primary"
              text
              @click="dialog_estornar_desconsideracao = null">
              Cancelar
            </v-btn>
            <v-btn
              class="btn white--text"
              color="primary accent-4"
              :loading="loading"
              @click="estornar_desconsideracao(dialog_estornar_desconsideracao)">
              Estornar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- Fim Dialogo Estornar -->

  </div>
  </template>

  <script>
  import store_site from "../../../store/store_site";
  import store_usuario from "../../../store/store_usuario";
  import { COR_PRINCIPAL, COR_SUBTITULO, COR_SECUNDARIA } from "../../../services/constantes";
  import { formatDate, formatPrice } from "../../../services/funcoes";
  import { API } from "../../../services/API";
  import moment from 'moment';
  // import ContasReceberNovoModal from "../ContasReceber/ContasReceberNovoModal.vue";
  // import ContasPagarNovoModal from "../ContasPagar/ContasPagarNovoModal.vue";
  // import ContasPagar from '../ContasPagar/ContasPagar.vue';
  // import ContasReceber from '../ContasReceber/ContasReceber.vue';
  // import ContasPagarModal from "../ContasPagar/ContasPagarModal.vue";
  import ConciliacaoBancariaLancamentos from "./ConciliacaoBancariaLancamentos.vue";
  import TransferenciaModal from "../Transferencia/TransferenciaModal.vue";

  export default {
    name: "ConciliacaoBancaria",

    components: {
      // ContasReceberNovoModal,
      // ContasPagarNovoModal,
      // ContasPagarModal,
      // ContasPagar,
      // ContasReceber,
      ConciliacaoBancariaLancamentos,
      TransferenciaModal
    },

    data() {
      return {

        store_site          : store_site,
        store_usuario       : store_usuario,

        loading             : false,
        loading_excluir     : false,
        loading_arquivo     : false,
        loading_cedente     : false,
        currentPage         : 1,
        idUpload            : 0,
        cobrancasPagarArray   : [],
        cobrancasReceberArray : [],
        extrato_movimento_aux : {},

        COR_PRINCIPAL           : COR_PRINCIPAL,
        COR_SUBTITULO           : COR_SUBTITULO,
        COR_SECUNDARIA          : COR_SECUNDARIA,
        formatDate              : formatDate,
        formatPrice             : formatPrice,
        accentColor             : '#f00',
        dialog_excluir          : false,
        dialog_excluir_alert    : false,
        dialog_excluir_msg      : false,
        dialog_excluir_message  : null,
        dialogIndiceCadastro    : false,
        dialog_lancamentos      : null,
        dialog_estornar         : null,
        dialog_estornar_transferencia : null,
        //dialog_ContasPagarNovoModal   : false,
        //dialog_ContasReceberNovoModal : false,
        dialog_lancamentos_consultar  : false,
        dialog_lancamentos_pagar      : false,
        dialog_ContasPagar            : null,
        dialog_ContasReceber          : null,
        dialog_TransferenciaModal     : null,

        dialog_DesconsiderarConciliacao : null,
        motivo_DesconsiderarConciliacao : null,
        dialog_estornar_desconsideracao : null,
        form                            : false,

        //INFO_HEADER
        empreend_nome       : null,
        nome_banco          : null,
        agencia_banco       : null,
        nome_cedente        : null,
        cod_cedente         : null,
        date1               : null, //format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        date1Aux            : null, //format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        date2               : null, //format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        date2Aux            : null, //format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        menu1               : null,
        menu2               : null,

        //TOTAIS RODAPÉ
        total_parcelas      : 0,
        total_pagar         : 0,
        total_pago          : 0,

        /* Mostra no SELECT BANCO */
        opcoes_banco: [],

        /* Mostra no SELECT CEDENTE */
        opcoes_cedente: [],

        /* Mostra no SELECT ARQUIVO */
        opcoes_arquivo: [],

        arquivo: null,

        /* TÍTULOS */
        headers: [
          { text: "", value: "", style: "text-align: center"},
          { text: "#", value: "#", style: "text-align: center"},
          { text: "Parcela", value: "Parcela", style: "text-align: center"},
          { text: "Situação", value: "Situação", style: "text-align: center"},
          { text: "Descrição", value: "Descrição", style: "text-align: center"},
          { text: "Dt Vencto", value: "Dt Vencto", style: "text-align: center"},
          { text: "Valor", value: "Valor", style: "text-align: center"},
          { text: "Dt Pagto", value: "Dt Pagto", style: "text-align: center"},
          { text: "Valor Pago", value: "Valor pago", style: "text-align: center"},
        ],

        banco_selecionado     : null,
        cedente_selecionado   : null,
        arquivo_selecionado   : null,

        itemSelecionado       : null,
        expand                : true,
        acao                  : 'C',
        importarPlanilha      : false,
        movimentos_dia        : null,

        /* dados dos extratos */
        dados: [],

        /* dados dos lancamentos da conciliação selecionada */
        dados_lancamentos         : [],
        dados_lancamentos_editar  : {},

        saldo_atual_calculado     : null,

        };
    },

    async mounted() {
      this.busca_bancos();
    },

    computed:{
      isMobile(){
        return this.$vuetify.breakpoint.name==='xs'
      },

      // Tamanho da tabela
      tableHeight() {
        if (this.isMobile)
          return window.innerHeight - 270;
        else
          return window.innerHeight - 270;
      },

      computedDate1FormattedMomentjs () {
        return this.date1 ? moment(this.date1).format('DD/MM/YYYY') : ''
      },
      computedDate2FormattedMomentjs () {
        return this.date2 ? moment(this.date2).format('DD/MM/YYYY') : ''
      },
    },

    watch: {
      banco_selecionado(val) {
        this.cedente_selecionado        = null;
        this.opcoes_cedente             = [];
        this.arquivo_selecionado        = null;
        this.opcoes_arquivo             = [];
        this.dados_registros            = [];
        this.total_parcelas             = null
        this.total_pagar                = null
        this.total_pago                 = null
        this.nome_banco                 = null;
        this.agencia_banco              = null;
        this.nome_cedente               = null;
        this.empreend_nome              = null;
        this.cod_cedente                = null;
        this.busca_cedente(val.cod_banco)
      },

      async cedente_selecionado(val){
        this.opcoes_arquivo             = [];
        this.total_parcelas             = null;
        this.total_pagar                = null;
        this.total_pago                 = null;
        this.nome_banco                 = null;
        this.agencia_banco              = null;
        this.nome_cedente               = null;
        this.empreend_nome              = null;
        this.cod_cedente                = null;
        this.dados                      = [];
        this.date1                      = null;
        this.date2                      = null;
        this.itemSelecionado            = null;
        this.movimentos_dia             = null;
        if (val) {
          this.saldo_atual_calculado     = null;
          await this.busca_extratos_dia(val.cod_pessoa_conta);
          if (this.movimentos_dia.length > 0) {
            await this.busca_extratos(val.cod_pessoa_conta,
                                      this.movimentos_dia[0].data,
                                      this.movimentos_dia[0].data);
            this.itemSelecionado = 0;
          }
        }
      },

      'dialog_lancamentos.extrato_mov_conc' () {
        if (this.dialog_lancamentos && this.dialog_lancamentos.extrato_mov_conc) {
          // console.log("🚀 ~ file: ConciliacaoBancaria.vue:968 ~ this.dialog_lancamentos:", this.dialog_lancamentos)
          
          this.dialog_lancamentos.total_conciliados = this.dialog_lancamentos.extrato_mov_conc.reduce((total, item) => total + Number(item.empreendcobr_pagto_valor), 0);
          this.dialog_lancamentos.total_conciliados = Number(this.dialog_lancamentos.total_conciliados.toFixed(2))
          this.dialog_lancamentos.quant_nao_pagos   = this.dialog_lancamentos.extrato_mov_conc.reduce((total, item) => total + (Number(item.empreendcobr_pagto_valor) <= 0 ? 1 : 0), 0);
          // console.log('WATCH dialog_lancamentos.extrato_mov_conc', this.dialog_lancamentos)
          this.cobrancasPagarArray = []
          this.dialog_lancamentos.extrato_mov_conc.forEach(val => {
            if (val.empreendcobr_situacao === 'Pago' && val.empreendcobr_tipo === 'P') {
              this.cobrancasPagarArray.push(val.cod_empreendcobranca)
              //console.log("🚀 ~ file: ConciliacaoBancaria.vue:1019 ~ val:", val)
            }
          })

          this.cobrancasReceberArray = []
          this.dialog_lancamentos.extrato_mov_conc.forEach(val => {
            if (val.empreendcobr_situacao === 'Pago' && val.empreendcobr_tipo === 'R') {
              this.cobrancasReceberArray.push(val.cod_empreendcobranca)
              //console.log("🚀 ~ receber:", val)
            }
          })
        }
      },

      arquivo(val) {
        console.log('WATCH arquivo', val)
        if (val)
          this.importa_extrato(val);
      }

    },

    methods: {
      async estornar_transferencia(item){
        this.loading = true

        const ls_Res = await API.post('/erp/financeiro/conciliacao/estornar_transferencia',
          {cod_extrato_movimento: item.cod_extrato_movimento, cod_fin_transferencia : item.extrato_mov_transf.cod_fin_transferencia});
        if (ls_Res.status == 200) {
          // console.log('SELECIONADO 1', this.itemSelecionado)
          const li_ItemSelecionado = this.itemSelecionado;
          await this.busca_extratos_dia(this.cedente_selecionado.cod_pessoa_conta);
          await this.busca_extratos(this.cedente_selecionado.cod_pessoa_conta, formatDate(this.date1), formatDate(this.date2));
          this.itemSelecionado = li_ItemSelecionado;
          // console.log('SELECIONADO 2', this.itemSelecionado)
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = ls_Res.data.result.result.trim();
          this.store_site.alert           = true;
        } else {
          this.store_site.alert_cor       = "#FF0000";
          this.store_site.alert_timeout   = 10000;
          this.store_site.alert_msg       = ls_Res.data.errors.trim();
          this.store_site.alert           = true;
        }
        this.dialog_estornar_transferencia  = null;
        this.loading = false
      },

      limparCodExtratoMoimento(){
        this.extrato_movimento_aux = {}
      },

      abrirModalTransferencia(item){
        this.extrato_movimento_aux = item
        this.dialog_TransferenciaModal = true
      },

      // Desconsidera esta conciliação, pois pode ser que o valor não tenha haver com o sistema
      async desconsiderarConciliacao(item) {
        this.loading = true

        const ls_Res = await API.post('/erp/financeiro/conciliacao/desconsiderar_conciliacao',
          {cod_extrato_movimento: item.cod_extrato_movimento, desconsideracao_motivo : this.motivo_DesconsiderarConciliacao});
        if (ls_Res.status == 200) {
          // console.log('SELECIONADO 1', this.itemSelecionado)
          const li_ItemSelecionado = this.itemSelecionado;
          await this.busca_extratos_dia(this.cedente_selecionado.cod_pessoa_conta);
          await this.busca_extratos(this.cedente_selecionado.cod_pessoa_conta, formatDate(this.date1), formatDate(this.date2));
          this.itemSelecionado = li_ItemSelecionado;
          // console.log('SELECIONADO 2', this.itemSelecionado)
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = ls_Res.data.result.result.trim();
          this.store_site.alert           = true;
          this.dialog_DesconsiderarConciliacao = null;
        } else {
          this.store_site.alert_cor       = "#FF0000";
          this.store_site.alert_timeout   = 10000;
          this.store_site.alert_msg       = ls_Res.data.errors.trim();
          this.store_site.alert           = true;
        }
        this.loading = false
      },

      // Adiciona o Item Selecionado na tela Modal de Seleção de Cobranças
      // lancamentosAdicionarItemSelecionado(item) {
      //   this.dialog_lancamentos.extrato_mov_conc.push(item);
      //   this.dialog_lancamentos_consultar = null;
      // },
      // saveConta(dados){
      //   dados.empreendcobr_parcela_inicial = 1
      //   this.dialog_lancamentos.extrato_mov_conc.push({...dados})
      //   this.dialog_ContasReceberNovoModal = false
      //   this.dialog_ContasPagarNovoModal = false
      // },
      // async editConta(item){
      //   item.empreendcobr_parcela_inicial = 1
      //   let lo_Index = await this.dialog_lancamentos.extrato_mov_conc.findIndex((val) => val.cod_empreendcobranca == item.cod_empreendcobranca);
      //   this.$set(this.dialog_lancamentos.extrato_mov_conc, lo_Index, item)
      //   this.dialog_ContasReceberNovoModal  = false;
      //   this.dialog_ContasPagarNovoModal    = false;
      //   this.dialog_lancamentos_consultar   = null;
      // },
      // async lancamentos_editar(item){
      //   const resposta = await API.get("/erp/contaspagar_individual", { params: { cod_empreendcobranca : item.cod_empreendcobranca}});

      //   this.dados_lancamentos_editar = resposta.data.result.rows[0]
      //   this.dados_lancamentos_editar.acao = 'editar'
      //   this.dialog_ContasPagarNovoModal = true
      // },

      getFile() {
        console.log('getFile')
        this.$refs.fileInput.$refs.input.click()
        this.arquivo = null;
      },

      handlePageChange(value) {
        this.currentPage = value;
      },

      // Função para enviar dados para o filho ContasPagarModal.vue pagamento/baixa
      // async pagar(item){
      //   const resposta = await API.get("/erp/contaspagar_individual", { params: { cod_empreendcobranca : item.cod_empreendcobranca}});

      //   if (resposta) {
      //     if (!!resposta.data.result) {
      //       this.dialog_lancamentos_pagar = resposta.data.result.rows[0];
      //       this.dialog_lancamentos_pagar.devedor = this.dialog_lancamentos_pagar.cobr_dev.pessoa_nome
      //       this.dialog_lancamentos_pagar.pagador = this.dialog_lancamentos_pagar.devedor
      //     }
      //     else
      //       this.dialog_lancamentos_pagar = [];
      //   }
      // },

      // Função para buscar bancos cadastrados
      async busca_bancos() {
        const resposta = await API.get("banco", {});
        if (resposta) {
          if (resposta.data.result != "Não possui dados") {
            this.opcoes_banco = resposta.data.result;
          } else this.opcoes_banco = [];
        }
      },

      // Função para buscar cedentes ao banco selecionado
      async busca_cedente(cod_banco) {
        this.loading_cedente  = true;
        this.date1            = null;
        this.date2            = null;
        this.dados            = [];
        const resposta = await API.get(`conta_pessoa_conciliacao`, {params:{pessoa_conta_tipo: 'B', cod_banco: cod_banco}});
        if (resposta) {
          if (resposta.data.result != "Não possui dados") {
            // const lo_Obj = resposta.data.result.map((e) => ({
            //                                               cod_empreendcobrancacnab            : e.cod_empreendcobrancacnab,
            //                                               cod_pessoa_conta                    : e.cod_pessoa_conta,
            //                                               cod_banco                           : e.cod_banco,

            //                                               cedente_nome                        : e.pessoa_nome,
            //                                               numerocpfcgc                        : e.numerocpfcgc,

            //                                               agencia_numero                      : e.agencia_numero,
            //                                               agencia_numero_dig                  : e.agencia_numero_dig,
            //                                               agencia_numero_com_dig              : e.agencia_numero_com_dig,

            //                                               pessoa_conta_numero                 : e.pessoa_conta_numero,
            //                                               pessoa_conta_numero_dig             : e.pessoa_conta_numero_dig,
            //                                               pessoa_conta_numero_com_dig         : e.pessoa_conta_numero_com_dig,

            //                                               empreend_boleto_cedente             : e.empreend_boleto_cedente,
            //                                               empreend_boleto_cedente_dv          : e.empreend_boleto_cedente_dv,
            //                                               empreend_boleto_cedente_com_dig     : e.empreend_boleto_cedente_com_dig,

            //                                               empreend_boleto_convenio            : e.empreend_boleto_convenio,
            //                                               empreend_boleto_convenio_dv         : e.empreend_boleto_convenio_dv,
            //                                               empreend_boleto_convenio_com_dig    : e.empreend_boleto_convenio_com_dig,
            //                                               pessoa_conta_saldoatual             : e.pessoa_conta_saldoatual,
            //                                               saldo_atual_calculado               : e.saldo_atual_calculado,
            //                                               cod_devedor_referencia              : e.cod_pessoa,
            //                                               devedor_referencia                  : {
            //                                                                                       cod_pessoa  : e.cod_pessoa,
            //                                                                                       nome_pessoa : e.pessoa_cedente
            //                                                                                     } ,

            //                                               cliente_conta            : e.pessoa_conta_descricao
            //                                               // cliente_conta            : e.pessoa_nome + ' | Ag:' +
            //                                               //                           e.agencia_numero + ' | Conta:' +
            //                                               //                           e.pessoa_conta_numero + ' Cedente:' +
            //                                               //                           e.empreend_boleto_cedente
            //                                   }));
            this.opcoes_cedente             = resposta.data.result;
            this.loading_cedente            = false;
          }
          else
            this.opcoes_cedente = [];
        }
        this.loading_cedente          = false;
      },

      // Buscando Extrato que já está salvo no banco de dados
      async busca_extratos(cod_pessoa_conta, data_inicial, data_final) {
console.log('this.cedente_selecionado::', this.cedente_selecionado)
        this.saldo_atual_calculado  = null;
        this.loading                = true;
        this.dados                  = [];
        const resposta = await API.get('/erp/financeiro/conciliacao/consulta_extrato', {params: { cod_pessoa_conta  : cod_pessoa_conta,
                                                                                                  data_inicial      : data_inicial,
                                                                                                  data_final        : data_final}});
        this.dados = resposta.data.result.data;
        // Calculando Totais
        this.dados.valor_total_extrato = this.dados.reduce((total, item) => total + parseFloat(item.valor), 0);
        this.dados.valor_total_sistema = this.dados.reduce((total, item) => total + (item.extrato_mov_conc.reduce((total, item2) => total + (item2.empreendcobr_tipo === 'R' ? item2.empreendcobr_pagto_valor : (item2.empreendcobr_pagto_valor * -1)), 0)), 0);
        this.dados.valor_total_sistema += this.dados.reduce((total, item) => total + (parseFloat(!item.extrato_mov_transf?.valor ? 0 : (item.extrato_mov_transf.debito_credito === 'C' ? parseFloat(item.extrato_mov_transf?.valor) : (parseFloat(item.extrato_mov_transf?.valor) * -1) ))),0)
        this.dados.valor_saldo_atual   = this.dados.reduce((total, item) => total + parseFloat(item.valor), 0);
        this.dados.falta_conciliar     = this.dados.reduce((total, item) => total + (item.extrato_mov_conc.length <= 0 ? (item.conciliado == 'DES' ? 0: 1): 0), 0);
        this.dados.falta_conciliar    -= this.dados.reduce((total, item) => total + (item.extrato_mov_transf?.valor ? 1: 0), 0);

        // Buscando Valor de Saldo. Precisa corrigir e deixar juntamente com as outras requisições
        this.saldo_atual_calculado     = this.dados[0].saldo_atual_calculado;

        // Aplicando datas de forma global para esta aplicação
        this.date1 = moment(data_inicial, 'DD/MM/YYYY').format('YYYY-MM-DD');
        this.date2 = moment(data_final, 'DD/MM/YYYY').format('YYYY-MM-DD');
        this.date1Aux = this.date1;
        this.date2Aux = this.date2;

        this.loading    = false;
      },

      // Buscando Extrato que já está salvo no banco de dados
      async busca_extratos_dia(cod_pessoa_conta) {
        this.movimentos_dia      = [];
        const resposta = await API.get('/erp/financeiro/conciliacao/consulta_extrato_dia', {params: { cod_pessoa_conta  : cod_pessoa_conta}});
        if (resposta.data.result.data.length > 0)
          this.movimentos_dia = resposta.data.result.data.map((o) => {
                                                                      return {data             : moment(o.data).format('DD/MM/YYYY'),
                                                                              qtde_movimentos  : o.qtde_movimentos,
                                                                              qtde_conciliados : o.qtde_conciliados,
                                                                              }
                                                                      });
      },

      // Importar extrato OFX do banco para o sistema
      async importa_extrato(arquivo) {
        var reader = new FileReader()
        reader.readAsDataURL(this.arquivo)
        reader.onload = async () => {
          this.store_site.overlay_global = true;
          const resposta = await API.post('/erp/financeiro/conciliacao/conciliacao_ofx',
                                          { arquivo: reader.result,
                                            cod_PessoaConta       : this.cedente_selecionado.cod_pessoa_conta,
                                            cod_Banco             : this.cedente_selecionado.pessoa_conta_agencia.cod_banco,
                                            BancoCodCompensacao   : this.cedente_selecionado.pessoa_conta_agencia.banco_compensacao,
                                            PessoaContaNumero     : this.cedente_selecionado.pessoa_conta_numero
                                          }
                                          );
          if (resposta.status == 200) {
            resposta.data.result.data.data_inicial
            this.date1 = moment(resposta.data.result.data.data_inicial).format('YYYY-MM-DD');
            this.date2 = moment(resposta.data.result.data.data_final).format('YYYY-MM-DD');
            this.date1Aux = this.date1;
            this.date2Aux = this.date2;
            //const li_ItemSelecionado = this.itemSelecionado;
            this.itemSelecionado = null;
            await this.busca_extratos_dia(this.cedente_selecionado.cod_pessoa_conta);
            await this.busca_extratos(this.cedente_selecionado.cod_pessoa_conta, formatDate(this.date1), formatDate(this.date2));
            //this.itemSelecionado = li_ItemSelecionado;
            this.store_site.alert_cor       = "#00A000";
            this.store_site.alert_timeout   = 1000;
            this.store_site.alert_msg       = resposta.data.result.result.trim();
            this.store_site.alert           = true;
          }
          else {
            console.log('resposta', resposta)
            this.store_site.alert_cor       = "#FF0000";
            this.store_site.alert_timeout   = 10000;
            this.store_site.alert_msg       = resposta.data.errors;
            this.store_site.alert           = true;
          }
          this.idUpload++;
          this.store_site.overlay_global    = false;
          this.arquivo                      = null;
        };
      },

      // Estornar a conciliação que já está feita
      async estornar(val) {
        const ls_Res = await API.post('/erp/financeiro/conciliacao/estornar_conciliacao', {cod_extrato_movimento: val});
        if (ls_Res.status == 200) {
          // console.log('SELECIONADO 1', this.itemSelecionado)
          const li_ItemSelecionado = this.itemSelecionado;
          await this.busca_extratos_dia(this.cedente_selecionado.cod_pessoa_conta);
          await this.busca_extratos(this.cedente_selecionado.cod_pessoa_conta, formatDate(this.date1), formatDate(this.date2));
          this.itemSelecionado = li_ItemSelecionado;
          // console.log('SELECIONADO 2', this.itemSelecionado)
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = ls_Res.data.result.result.trim();
          this.store_site.alert           = true;
        } else {
          this.store_site.alert_cor       = "#FF0000";
          this.store_site.alert_timeout   = 10000;
          this.store_site.alert_msg       = ls_Res.data.errors.trim();
          this.store_site.alert           = true;
        }
        this.dialog_estornar  = null;
      },

      async atualiza_extrato() {
        this.dialog_lancamentos = null;
        this.dados_lancamentos  = null;
        const li_ItemSelecionado = this.itemSelecionado;
        await this.busca_extratos_dia(this.cedente_selecionado.cod_pessoa_conta);
        await this.busca_extratos(this.cedente_selecionado.cod_pessoa_conta, formatDate(this.date1), formatDate(this.date2));
        this.itemSelecionado = li_ItemSelecionado;
      },

      // Cadastra Novo lançamento de contas a pagar/receber utilizando mesmo compontente que o consulta que o contas a pagar usa ou que o contas a receber usa
      // async lancamentos_novo(tipo) {
      //   this.dados_lancamentos_editar                                     = [];
      //   this.dados_lancamentos_editar.empreend_venda                      = {};
      //   this.dados_lancamentos_editar.empreend_venda.vendas               = {};
      //   this.dados_lancamentos_editar.empreend_venda.pessoa_venda         = {};
      //   this.dados_lancamentos_editar.empreend_venda.empreendvenda_imovel = {};
      //   this.dados_lancamentos_editar.acao                                = 'novo'
      //   tipo == 'C' ? this.dialog_ContasReceberNovoModal = true : null;
      //   tipo == 'D' ? this.dialog_ContasPagarNovoModal   = true : null;
      // },

      // Consulta lançamento de contas a pagar/receber através do componente Modal de Consulta de Cobranças
      // async lancamentos_consultar(item_selecionado) {
      //   cosole.log('')
      // },

      // Consulta lançamento de contas a pagar/receber através do componente Modal de Consulta de Cobranças
      // async lancamentos_conciliar() {
      //   const ls_ArrayCodEmpreendCobranca = this.dialog_lancamentos.extrato_mov_conc.map(o => { return o.cod_empreendcobranca}).join(',')
      //   const lo_OBJ = {cod_extrato_movimento   : this.dialog_lancamentos.cod_extrato_movimento,
      //                   cod_empreendcobrancas   : ls_ArrayCodEmpreendCobranca
      //   }

      //   const ls_Res = await API.post('/erp/financeiro/conciliacao/lancamentos_conciliar', lo_OBJ);
      //   if (ls_Res.status == 200) {
      //     const li_ItemSelecionado = this.itemSelecionado;
      //     await this.busca_extratos_dia(this.cedente_selecionado.cod_pessoa_conta);
      //     await this.busca_extratos(this.cedente_selecionado.cod_pessoa_conta, formatDate(this.date1), formatDate(this.date2));
      //     this.itemSelecionado = li_ItemSelecionado;
      //     this.store_site.alert_cor       = "#00A000";
      //     this.store_site.alert_timeout   = 1500;
      //     this.store_site.alert_msg       = ls_Res.data.result.result.trim();
      //     this.store_site.alert           = true;
      //     this.dialog_lancamentos         = null;
      //   }
      //   else {
      //     this.store_site.alert_cor       = "#FF0000";
      //     this.store_site.alert_timeout   = 10000;
      //     this.store_site.alert_msg       = ls_Res.data.errors.trim();
      //     this.store_site.alert           = true;
      //   }
      // },

      // Função que recebe alterações do filho ContasPagarModal.vue para atualizar a grade
      // async lancamentos_atualiza_registro(p_JSON) {
      //  let lo_Index = await this.dialog_lancamentos.extrato_mov_conc.findIndex((val) => val.cod_empreendcobranca == p_JSON.cod_empreendcobranca);
      //   this.$set(this.dialog_lancamentos.extrato_mov_conc, lo_Index, p_JSON)
      // },

      // Excluir pagamento dos lançamentos selecionados
      // async lancamentos_excluir(cod_) {
      //   const ls_Res = await API.post('/erp/financeiro/conciliacao/estornar_conciliacao', {cod_extrato_movimento: val});
      //   if (ls_Res.status == 200) {
      //     this.busca_extratos(this.cedente_selecionado.cod_pessoa_conta, formatDate(this.date1), formatDate(this.date2));
      //     this.store_site.alert_cor       = "#00A000";
      //     this.store_site.alert_timeout   = 1500;
      //     this.store_site.alert_msg       = ls_Res.data.result.result.trim();
      //     this.store_site.alert           = true;
      //   } else {
      //     this.store_site.alert_cor       = "#FF0000";
      //     this.store_site.alert_timeout   = 10000;
      //     this.store_site.alert_msg       = ls_Res.data.errors.trim();
      //     this.store_site.alert           = true;
      //   }
      //   this.dialog_estornar  = null;
      // }


    },

  };
  </script>

  <style lang="scss" scoped>

  /* ---------- BARRA DE SCROLL ---------- */
  .card::-webkit-scrollbar {
    width: 5px;
  }
  .card-list::-webkit-scrollbar {
    width: 5px;
  }
  .scroll-direita::-webkit-scrollbar {
    width: 5px;
  }
    .card::-webkit-scrollbar-button {
    padding: 1px;
  }
  .card-list::-webkit-scrollbar-button {
    padding: 1px;
  }
  .scroll-direita::-webkit-scrollbar-button {
    padding: 1px;
  }
  .card::-webkit-scrollbar-thumb {
    background: #CECECE;
    border-radius: 50px;
  }
  .card-list::-webkit-scrollbar-thumb {
    background: #CECECE;
    border-radius: 50px;
  }
  .scroll-direita::-webkit-scrollbar-thumb {
    background: #CECECE;
    border-radius: 50px;
  }
  .card::-webkit-scrollbar-thumb:hover {
    background: #A7A7A7;
    height: 10px;
  }
  .card-list::-webkit-scrollbar-thumb:hover {
    background: #A7A7A7;
    height: 10px;
  }
  .scroll-direita::-webkit-scrollbar-thumb:hover {
    background: #A7A7A7;
    height: 10px;
  }
  .card {
    height: calc(100vh - 175px) !important;
    overflow-y: auto;
    overflow-x: hide;
  }
  .card-list {
    min-width: 230px;
    height: calc(100vh - 405px) !important;
    overflow-y: auto;
    overflow-x: hide;
  }
  .scroll-direita {
    height: calc(100vh - 240px) !important;
    overflow-y: auto;
    overflow-x: hide;
  }

  .scroll {
   overflow-y: scroll
  }

  .title-page {
    width: 600px !important;
    height: 80px;
    font-family: "Open Sans", sans-serif !important;
    font-size: 17px !important;
  }

  @media(max-width: 599px) {
    .title-page {
      width: 100% !important;
    }

    .text-opacity {
      opacity: 0.7;
    }
  }

  @media(max-width: 375px) {
    .tab-mobile {
      margin-left: -44px;
    }
  }

  .text-white {
    color: #FFFFFF;
    letter-spacing: 0px;
  }

  .btn-icon:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  .btn-icon:hover .icon-action {
    color: #FFF !important;
    opacity: 0.8;
  }

  .nav-link {
    text-decoration: none;
  }

  .container {
    max-width: 100%;
    height: calc(100vh - 310px);
  }

  .divisor-horizontal {
    // display: none;
    width: 100% !important;
    position: relative;
  }

  .divisor-vertical {
    // display: none;
    height: 100% !important;
    position: relative;
    color: #FF0000 !important;
  }

  .size-icon-expanded {
    font-size: 15px;
  }

  .list {
    background-color: transparent;
    min-width: 220px;
    margin-top: -2px;
    color: var(--COR_SUBTITULO);
    height: calc(100vh - 275px) !important;
  }
  .bg-active {
    background-color: var(--COR_PRINCIPAL);
    color: white !important;
  }

  .corpo-fundo-cor {
    background-color: var(--COR_BACKGROUND) !important;
  }

  // Muda a cor de borda do outlined
  ::v-deep .v-input--is-focused .v-input__slot fieldset   {
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
  }

  ::v-deep .background-hover::before {
    background-color: var(--COR_PRINCIPAL) !important;
    border: 1px solid black;
  }

  ::v-deep .outlined-btn {
    border: 1px solid transparent;
  }

  .status_aberto_vencer {
    font-size: 12px !important;
    /* background: #6C87A2; */
    background: rgb(242, 192, 98);
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }
  .status_aberto_vencida {
    font-size: 12px !important;
    /* background: #6C87A2; */
    background: #FF5252;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }

  .status_pago {
    font-size: 12px !important;
    background: #469c47;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }

  .status_inativo {
    font-size: 12px !important;
    background: #525252;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }

  .status_rescindido {
    font-size: 12px !important;
    background: #A2A2A2;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }
  .title-page {
    font-family: "Open Sans", sans-serif!important;
    font-size: 19px!important;
    color: #FFFFFF;
    letter-spacing: 0px;
  }

  .title-page-menor {
    font-family: "Open Sans", sans-serif!important;
    font-size: 16px!important;
    color: #FFFFFF;
    letter-spacing: 0px;
  }


</style>
