<template>
  <div id="ConciliacaoBancariaLancamentos">
    <v-card>
      <!-- Cabeçalho -->
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
                class="justify-space-between py-1 px-4">
        <div>
          <!--Cabeçalho padrão para ações de pagar e de Edição -->
          <div>
            <span class="title-page">Lançamentos que irão compor a conciliação do extrato abaixo:</span>
          </div>
        </div>
        <v-btn @click="close()" icon dark color="#F2F6F7">
          <v-icon large class="title">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-- Texto com dados da conciliação -->
      <v-card-text v-bind:style="{ 'background-color':COR_PRINCIPAL, 'width':'100%!important' }"
                    class="justify-space-between px-0 mb-n1">
        <v-divider dark></v-divider>
        <div class="px-7" v-bind:style="{ 'background-color':COR_PRINCIPAL }">
          <!-- Cabecalho -------------------- -->
          <v-row class="mt-1">
            <v-col cols="7">
              <v-row>
                <label style="color:silver;font-size:15px;">Descrição:</label>
                <label style="color:white;font-size:15px;" class="ml-2">{{ dialog_lancamentos.descricao }}</label>
              </v-row>
              <v-row>
                <label style="color:silver;font-size:14px;">Data do Movimento:</label>
                <label style="color:white;font-size:15px;" class="ml-2">{{ formatDate(dialog_lancamentos.data) }}</label>
              
                <label style="color:silver;font-size:14px;" class="ml-6">Valor do Movimento:</label>
                <label style="color:white;font-size:15px;" class="ml-2">R$ {{ formatPrice(dialog_lancamentos.valor) }}</label>
              </v-row>
            </v-col>
            <v-col class="d-flex justify-space-between" cols="5" align="right">

              <!-- Botão Consultar Contas Receber -------------------------- -->
              <v-btn  v-if="dialog_lancamentos.debito_credito == 'C'"
                      v-on:click="dialog_ContasReceber = true; dialog_ContasPagar = null"
                      class="text-white mt-n2 mb-n2"
                      :color="COR_PRINCIPAL"
                      elevation="0"
                      :small="isMobile">
                <v-icon dense class="mr-1" color="green accent-2">mdi-magnify</v-icon>
                <span class="text-none">Consultar Receita existente</span>
              </v-btn>

              <!-- Botão Consultar Contas Pagar-------------------------- -->
              <v-btn  v-if="dialog_lancamentos.debito_credito == 'D'"
                      v-on:click="dialog_ContasPagar = true; dialog_ContasReceber = null"
                      class="text-white mt-n2 mb-n2"
                      :color="COR_PRINCIPAL"
                      elevation="0"
                      :small="isMobile">
                <v-icon dense class="mr-1" color="green accent-2">mdi-magnify</v-icon>
                <span class="text-none">Consultar Despesa existente</span>
              </v-btn>

              <!-- Botão Novo ------------------------------- -->
              <v-btn v-if="dialog_lancamentos.debito_credito == 'D' " v-on:click="lancamentos_novo('D')" class="text-white mt-n2 mb-n2" :color="COR_PRINCIPAL" elevation="0" :small="isMobile">
                <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
                <span class="text-none">Cadastrar nova despesa</span>
              </v-btn>
              <v-btn v-show="false" v-if="dialog_lancamentos.debito_credito == 'C' " v-on:click="lancamentos_novo('C')" class="text-white mt-n2 mb-n2" :color="COR_PRINCIPAL" elevation="0" :small="isMobile">
                <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
                <span class="text-none">Cadastrar nova receita</span>
              </v-btn>

            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <!-- Corpo do Dialog -->
      <v-card-text class="pa-0">
        <v-data-table
          style="white-space: nowrap; min-height: 45vh; max-height: 55vh; overflow-y: auto; overflow-x: hidden"
          :items="dialog_lancamentos.extrato_mov_conc"
          :items-per-page="1000"
          dense
          fixed-header
          hide-default-footer
          loading-text="Carregando...  aguarde..."
          no-data-text="Nenhum Registro Encontrado"
          no-results-text="Nenhum Registro Encontrado"
          >
          <template v-slot:header>
            <thead>
              <tr>
                <th v-for="h in headers" :key="h.value" :style="h.style">
                  <span style="font-size: 12px">{{h.text}}</span>
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{item, index}">
            <tr class="px-1">

              <!-- Botoes de Ação -->
              <td >
                <v-menu content-class="my-menu" 
                        transition="slide-y-transition"
                        bottom
                        :disabled="dialog_lancamentos.conciliado == 'SIM'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <!-- v-if="store_usuario.priv_busca_privilegios_aplicacao_evento('PrivilegioUsuarioAplicacao_alterar')" -->
                    <v-btn
                      color="grey"
                      dark
                      icon
                      v-bind="attrs"
                      v-on="on"
                      class="ml-n2 mr-n4"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <!-- Menu Editar / Excluir / etc -->
                  <v-card elevation="0" :border="false" style="background-color:#FFFFFF">
                    <v-list-item
                      v-if="item.empreendcobr_situacao != 'Pago'"
                      @click="lancamentos_editar(item)">
                      <v-icon color="green" class="mr-2 icon-menu">
                        mdi-pencil
                      </v-icon>
                      Editar lançamento
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      @click="dialog_lancamentos.extrato_mov_conc.splice(index,1)">
                      <v-icon color="red" class="mr-2 icon-menu">mdi-delete</v-icon>
                      Remover este lançamento da grade
                    </v-list-item>
                  </v-card>
                  <!-- FIM Menu Editar / Excluir / etc -->

                </v-menu>
              </td>

              <td class="py-1">
                <span>{{ item.cod_empreendcobranca }}</span>
              </td>
              <td style="text-align: center">
                <span>{{ item.empreendcobr_parcela_inicial }} / {{ item.empreendcobr_parcela_final }}</span>
              </td>
              <td style="text-align: center">
                <span
                  v-if="item.empreendcobr_situacao == 'Aberto' && moment(item.empreendcobr_parc_dt).format() >= moment(moment(new Date()).format('YYYY-MM-DD')).format()"
                  class="status_aberto_vencer">
                  <v-icon color="#FFFFFF" class="ml-n2" dense >
                    mdi-alarm
                  </v-icon>
                  {{ item.empreendcobr_situacao }}
                </span>
                <span
                  v-if="item.empreendcobr_situacao == 'Aberto' && moment(item.empreendcobr_parc_dt).format() < moment(moment(new Date()).format('YYYY-MM-DD')).format()"
                  class="status_aberto_vencida"
                ><v-icon color="#FFFFFF" class="ml-n2" dense >mdi-alert-octagon-outline</v-icon>{{ item.empreendcobr_situacao }}
                </span>
                <span
                  v-if="item.empreendcobr_situacao == 'Pago'"
                  class="status_pago"
                  ><v-icon color="#FFFFFF" class="ml-n2" dense >mdi-check</v-icon>{{ item.empreendcobr_situacao }}
                </span>
                <span
                  v-if="item.empreendcobr_situacao == 'Rescindido'"
                  class="status_rescindido"
                  >{{ item.empreendcobr_situacao }}
                </span>
                <span
                  v-if="item.empreendcobr_situacao == 'Inativo'"
                  class="status_inativo"
                  >{{ item.empreendcobr_situacao }}
                </span>
              </td>
              <td style="width:50%">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span style="font-size:12px"  v-bind="attrs" v-on="on">
                      {{ item.empreendcobr_descricao.substring(0, item.empreendcobr_nf ? 45 : 55) }} 
                      {{ item.empreendcobr_descricao.length > 45 ? '...' : '' }} {{item.empreendcobr_nf ? `(NF: ${item.empreendcobr_nf})` : ''}}</span>
                  </template>
                  <span style="font-size:12px">{{item.empreendcobr_descricao}} {{item.empreendcobr_nf ? `(NF: ${item.empreendcobr_nf})` : ''}}</span>
                </v-tooltip>
              </td>
              <td class="px-2" style="text-align: center; min-width:70px!important; background-color:transparent;">
                <span>{{ formatDate(item.empreendcobr_parc_dt) }}</span>
              </td>
              <td class="px-2" style="text-align: right; min-width:120px; background-color: transparent;">
                <span>{{ item.empreendcobr_parc_valor_final ? 'R$ ' + formatPrice(item.empreendcobr_parc_valor_final) : '' }}</span>
              </td>
              <td class="px-2" style="text-align: center; min-width:70px!important; background-color: transparent;">
                <span>{{ formatDate(item.empreendcobr_pagto_dt) }}</span>
              </td>
              <td class="px-2 pr-5" style="text-align: right; min-width:120px; background-color: transparent;">
                <span v-if="item.empreendcobr_pagto_dt" >{{ item.empreendcobr_pagto_valor ? 'R$ ' + formatPrice(item.empreendcobr_pagto_valor) : '' }}</span>
                <v-tooltip v-else top>
                  <span class="text-none">Pagar este lançamento</span>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="pagar(item)" color="#E9E9E9" style="background-color: #FFFFFF;" class="background-hover mr-4 mt-1 mb-1" width="40px" height="40px" dense fab elevation="0" outlined>
                      <v-icon color="green lighten-1" dense dark >mdi-cash</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-text class="d-flex justify-end pt-2">
        <!-- Possui pagamentos não pagos -->
        <span v-if="dialog_lancamentos.quant_nao_pagos > 0" class="d-flex justify-end" v-bind:style="{ 'font-size': '17px', 'font-weight': '600', 'color': 'red' }">
          <span class="pa-1 mr-4">Permitido somente lançamentos pagos</span>
          <span class="pa-1 pl-7 d-flex justify-end" :style="{'width':'150px', 'background-color':'#FF0000', 'font-size': '17px', 'font-weight': '600', 'color': '#FFFFFF' }">
            <strong>R$ {{ dialog_lancamentos.total_conciliados ? formatPrice(dialog_lancamentos.total_conciliados) : 0 }}</strong>
          </span>
        </span>
        <!-- Possui somente pagamentos pagos porém os totais não batem -->
        <span
          v-if="dialog_lancamentos.quant_nao_pagos <= 0 && Math.abs(dialog_lancamentos.valor) != dialog_lancamentos.total_conciliados"
          class="d-flex justify-end"
          :style="{ 'font-size': '17px', 'font-weight': '600', 'color': 'red' }">
          <span class="pa-1 mr-4">Os totais dos lançamentos devem ser iguais aos totais do extrato selecionado:</span>
          <span class="pa-1 pl-7 d-flex justify-end" :style="{'width':'150px', 'background-color':'#FF0000', 'font-size': '17px', 'font-weight': '600', 'color': '#FFFFFF' }">
            <strong>R$ {{ dialog_lancamentos.total_conciliados ? formatPrice(dialog_lancamentos.total_conciliados) : 0 }}</strong>
          </span>
          <!-- <pre>{{ dialog_lancamentos }}</pre> -->
        </span>
        <!-- Possui somente pagamentos e os totaisbatem -->
        <span v-if="dialog_lancamentos.quant_nao_pagos <= 0 && Math.abs(dialog_lancamentos.valor) == dialog_lancamentos.total_conciliados" class="d-flex justify-end" :style="{ 'font-size': '17px', 'font-weight': '600', 'color': 'green' }">
          <span class="pa-1 mr-4">Total dos lançamentos selecionados:</span>
          <span class="pa-1 pl-7 d-flex justify-end" :style="{'width':'150px', 'background-color':'green', 'font-size': '17px', 'font-weight': '600', 'color': '#FFFFFF' }">
            <strong>R$ {{ formatPrice(dialog_lancamentos.total_conciliados) }}</strong>
          </span>
        </span>
      </v-card-text>
      <v-divider class="mt-n3 mb-2"></v-divider>
      <v-card-text class="d-flex justify-end mb-n4">
        <span class="d-flex justify-end" v-bind:style="{ 'font-size': '17px', 'font-weight': '600', 'color': 'green' }">
          <span class="pa-1 mr-4">Total do movimento selecionado do extrato bancário:</span>
          <span class="pa-1 pl-7 d-flex justify-end" v-bind:style="{'width':'150px', 'font-size': '17px', 'font-weight': '600', 'green': '#FFFFFF' }">
            <strong>R$ {{ Math.abs(Number(dialog_lancamentos.valor)) }}</strong>
          </span>
        </span>            
      </v-card-text>

      <!-- Rodape do Dialog -->
      <v-footer
        absolute
        color="#F2F6F7"
        elevation="0"
        class="d-flex justify-center pa-0 mb-3">

        <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }" style="width:100%">
          <v-btn
            @click="close()"
            v-show="dialog_lancamentos.conciliado == 'NÃO'"
            class="mr-4 btn text-none"
            color="primary"
            text
          >
            Cancelar
          </v-btn>
          <v-btn
            @click="close()"
            v-show="dialog_lancamentos.conciliado == 'SIM'"
            class="mr-4 btn text-none"
            color="primary"
            text
          >
            Fechar
          </v-btn>
          <v-btn
            @click="lancamentos_conciliar()"
            class="mr-4 btn text-none"
            color="primary"
            elevation="0"
            v-show="dialog_lancamentos.conciliado == 'NÃO'"
            :disabled="!(dialog_lancamentos.quant_nao_pagos <= 0 && Math.abs(dialog_lancamentos.valor) == dialog_lancamentos.total_conciliados)"
          >
            Salvar
          </v-btn>
        </v-card-actions>
        </v-footer>
    </v-card>

    <!-- Dialog Novo Contas Receber-->
    <v-container v-if="dialog_ContasReceberNovoModal">
      <v-dialog
        v-model="dialog_ContasReceberNovoModal"
        transition="dialog-bottom-transition"
        min-height="200"
        max-width="600"
        min-width="520"
      >
        <ContasReceberNovoModal
          :dialog_novo.sync="dialog_ContasReceberNovoModal"
          :dados_editar="dados_lancamentos_editar"
          @criarNovaConta="saveConta"
          @editarConta="editConta"
        />
      </v-dialog>
    </v-container>

    <!-- Dialog Novo Contas Pagar-->
    <v-container v-if="dialog_ContasPagarNovoModal">
      <v-dialog
        persistent
        v-model="dialog_ContasPagarNovoModal"
        transition="dialog-bottom-transition"
        max-height="300"
        min-height="200"
        max-width="600"
        min-width="520">
        <ContasPagarNovoModal
          :dialog_novo.sync="dialog_ContasPagarNovoModal"
          :dados_editar="dados_lancamentos_editar"
          :devedor_referencia="cedente_selecionado.devedor_referencia"
          :dialog_lancamentos="dialog_lancamentos"
          :cedente_selecionado="cedente_selecionado"
          @criarNovaConta="saveConta"
          @editarConta="editConta"
           />
          <!-- @atualizaRegistro="lancamentos_atualiza_registro"
          @criarNovaConta="lancamentos_saveConta"
          @editarConta="lancamentos_editConta"
          @atualizaItem="lancamentos_atualizaItem" -->
      </v-dialog>
    </v-container>

    <!-- Dialog Pagar -->
    <v-container v-if="dialog_lancamentos_pagar">
      <v-dialog
        persistent
        v-model="dialog_lancamentos_pagar"
        transition="dialog-bottom-transition"
        max-height="300"
        min-height="200"
        max-width="600"
        min-width="520">
        <ContasPagarModal
          :dialog_novo_editar.sync="dialog_lancamentos_pagar"
          :dados_editar="dialog_lancamentos_pagar"
          :dialog_lancamentos="dialog_lancamentos"
          :cedente_selecionado="cedente_selecionado"
          @atualizaRegistro="lancamentos_atualiza_registro"
           />
          <!-- @atualizaRegistro="lancamentos_atualiza_registro"
          @criarNovaConta="lancamentos_saveConta"
          @editarConta="lancamentos_editConta"
          @atualizaItem="lancamentos_atualizaItem" -->
      </v-dialog>
    </v-container>

    <!-- Dialog Receber -->
    <v-container v-if="dialog_lancamentos_receber">
      <v-dialog
        persistent
        v-model="dialog_lancamentos_receber"
        transition="dialog-bottom-transition"
        max-width="800"
        min-width="520">
        <ContasReceberModal
          :dialog_novo_editar.sync="dialog_lancamentos_receber"
          :dados_editar="dialog_lancamentos_receber"
          @atualizaRegistro="atualiza_registro"
          
          />
          <!-- :dialog_novo_editar.sync="dialog_lancamentos_pagar"
          :dados_editar="dialog_lancamentos_pagar"
          @atualizaRegistro="lancamentos_atualiza_registro"
          height="900px" /> -->
          <!-- @atualizaRegistro="lancamentos_atualiza_registro"
          @criarNovaConta="lancamentos_saveConta"
          @editarConta="lancamentos_editConta"
          @atualizaItem="lancamentos_atualizaItem" -->
      </v-dialog>
    </v-container>

    <!-- Dialog Consultar Contas Receber -->
    <v-container v-if="dialog_ContasReceber">
      <v-dialog
        v-model="dialog_ContasReceber"
        transition="dialog-bottom-transition"
        max-height="300"
        min-height="200"
        min-width="700">
        <ContasReceber
          acao="SELECIONAR"
          @itemSelecionado="lancamentosAdicionarItemSelecionado"
          :itens_array_pai="cobrancasReceberArray"
          :dialog_ContasReceber.sync="dialog_ContasReceber"
          :dialog_lancamentos="dialog_lancamentos"
          />
      </v-dialog>
    </v-container>

    <!-- Dialog Consultar Contas Pagar -->
    <v-container v-if="dialog_ContasPagar">
      <v-dialog
        v-model="dialog_ContasPagar"
        transition="dialog-bottom-transition"
        max-height="300"
        min-height="200"
        min-width="700">
        <ContasPagar
          acao="SELECIONAR"
          @itemSelecionado="lancamentosAdicionarItemSelecionado"
          :dialog_ContasPagar.sync="dialog_ContasPagar"
          :itens_array_pai="cobrancasPagarArray"
          :parc_valor="dialog_lancamentos.valor"
          :parc_vencto="dialog_lancamentos.data"
          :dialog_lancamentos="dialog_lancamentos"
          :cedente_selecionado="cedente_selecionado"
          />
      </v-dialog>
    </v-container>

  </div>
</template>
  
  <script>
  import store_site from "../../../store/store_site";
  import store_usuario from "../../../store/store_usuario";
  import { COR_PRINCIPAL, COR_SUBTITULO, COR_SECUNDARIA } from "../../../services/constantes";
  import { formatDate, formatPrice } from "../../../services/funcoes";
  import { API } from "../../../services/API";
  import moment from 'moment';
  import ContasReceberNovoModal from "../ContasReceber/ContasReceberNovoModal.vue";
  import ContasPagarNovoModal from "../ContasPagar/ContasPagarNovoModal.vue";
  import ContasPagar from '../ContasPagar/ContasPagar.vue';
  import ContasReceber from '../ContasReceber/ContasReceber.vue';
  import ContasPagarModal from "../ContasPagar/ContasPagarModal.vue";
  import ContasReceberModal from "../ContasReceber/ContasReceberModal.vue";
  
  export default {
    name: "ConciliacaoBancariaLancamentos",

    props: ['dialog_lancamentos', 'cedente_selecionado'],

    components: {
      ContasReceberNovoModal,
      ContasPagarNovoModal,
      ContasPagarModal,
      ContasReceberModal,
      ContasPagar,
      ContasReceber
    },
  
    data() {
      return {

        store_site          : store_site,
        store_usuario       : store_usuario,
  
        loading             : false,
        loading_excluir     : false,
        loading_arquivo     : false,
        loading_cedente     : false,
        currentPage         : 1,
        idUpload            : 0,
        cobrancasPagarArray   : [],
        cobrancasReceberArray : [],

        COR_PRINCIPAL           : COR_PRINCIPAL,
        COR_SUBTITULO           : COR_SUBTITULO,
        COR_SECUNDARIA          : COR_SECUNDARIA,
        formatDate              : formatDate,
        formatPrice             : formatPrice,
        accentColor             : '#f00',
        dialog_excluir          : false,
        dialog_excluir_alert    : false,
        dialog_excluir_msg      : false,
        dialog_excluir_message  : null,
        dialogIndiceCadastro    : false,
        //dialog_lancamentos      : null,
        dialog_estornar         : null,
        dialog_ContasPagarNovoModal   : false,
        dialog_ContasReceberNovoModal : false,
        dialog_lancamentos_consultar  : false,
        dialog_lancamentos_pagar      : false,
        dialog_ContasPagar            : null,
        dialog_ContasReceber          : null,
        dialog_lancamentos_receber    : false,
        
        //INFO_HEADER
        empreend_nome       : null,
        nome_banco          : null,
        agencia_banco       : null,
        nome_cedente        : null, 
        cod_cedente         : null,
        date1               : null, //format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        date1Aux            : null, //format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        date2               : null, //format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        date2Aux            : null, //format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        menu1               : null,
        menu2               : null,
  
        //TOTAIS RODAPÉ
        total_parcelas      : 0,
        total_pagar         : 0,
        total_pago          : 0,
  
        /* Mostra no SELECT BANCO */
        opcoes_banco: [],
  
        /* Mostra no SELECT CEDENTE */
        opcoes_cedente: [],
  
        /* Mostra no SELECT ARQUIVO */
        opcoes_arquivo: [],
  
        arquivo: null,
  
        /* TÍTULOS */
        headers: [
          { text: "", value: "", style: "text-align: center"},
          { text: "#", value: "#", style: "text-align: center"},
          { text: "Parcela", value: "Parcela", style: "text-align: center"},
          { text: "Situação", value: "Situação", style: "text-align: center"},
          { text: "Descrição", value: "Descrição", style: "text-align: center"},
          { text: "Dt Vencto", value: "Dt Vencto", style: "text-align: center"},
          { text: "Valor", value: "Valor", style: "text-align: center"},
          { text: "Dt Pagto", value: "Dt Pagto", style: "text-align: center"},
          { text: "Valor Pago", value: "Valor pago", style: "text-align: center"},
        ],
       
        banco_selecionado     : null,
        //cedente_selecionado   : null,
        arquivo_selecionado   : null,
  
        itemSelecionado       : null,
        expand                : true,
        acao                  : 'C',
        importarPlanilha      : false,
        movimentos_dia        : null,

        /* dados dos extratos */
        dados: [],

        /* dados dos lancamentos da conciliação selecionada */
        dados_lancamentos         : [],
        dados_lancamentos_editar  : {}
  
        };
    },

    computed:{
      isMobile(){
        return this.$vuetify.breakpoint.name==='xs'
      },
  
      // Tamanho da tabela
      tableHeight() {
        if (this.isMobile)
          return window.innerHeight - 270;
        else
          return window.innerHeight - 500;
      },  
    },
  
    watch: {
    },

    methods: {
      async atualiza_registro(p_JSON) {
      let lo_Retorno = await this.cobrancas.find((val) => val.cod_empreendcobranca == p_JSON.cod_empreendcobranca);
      if (lo_Retorno) {
        Object.keys(p_JSON).forEach(function (key) {
          lo_Retorno[key] = p_JSON[key];
        });  
      }
    },
      // FECHA O DIALOGO
      close(){
        this.$emit('close')
      },

      // Adiciona o Item Selecionado na tela Modal de Seleção de Cobranças
      lancamentosAdicionarItemSelecionado(item) {
        item.map((o) =>  {
          this.dialog_lancamentos.extrato_mov_conc.push(o);
        });
        this.dialog_lancamentos_consultar = null;
      },
      saveConta(dados){
        dados.empreendcobr_parcela_inicial = 1
        this.dialog_lancamentos.extrato_mov_conc.push({...dados}) 
        // console.log("🚀 ~ file: ConciliacaoBancariaLancamentos.vue:526 ~ saveConta ~ this.dialog_lancamentos:", this.dialog_lancamentos)
        this.dialog_ContasReceberNovoModal = false
        this.dialog_ContasPagarNovoModal = false
      },

      async editConta(item){
        item.empreendcobr_parcela_inicial = 1
        let lo_Index = await this.dialog_lancamentos.extrato_mov_conc.findIndex((val) => val.cod_empreendcobranca == item.cod_empreendcobranca);
        this.$set(this.dialog_lancamentos.extrato_mov_conc, lo_Index, item)
        this.dialog_ContasReceberNovoModal  = false;
        this.dialog_ContasPagarNovoModal    = false;
        this.dialog_lancamentos_consultar   = null;
      },
      async lancamentos_editar(item){
        const resposta = await API.get("/erp/contaspagar_individual", { params: { cod_empreendcobranca : item.cod_empreendcobranca}});
        this.dados_lancamentos_editar = resposta.data.result.rows[0]
        this.dados_lancamentos_editar.acao = 'editar'
        this.dialog_ContasPagarNovoModal = true
      },

      // Função para enviar dados para o filho ContasPagarModal.vue pagamento/baixa 
      async pagar(item){
        var resposta = null
        if (item.empreendcobr_tipo === 'P') {
          resposta = await API.get("/erp/contaspagar_individual", { params: { cod_empreendcobranca : item.cod_empreendcobranca}});
        } else {
          resposta = await API.get("erp/contasreceber", { params: { cod_empreendcobranca : item.cod_empreendcobranca}});
        }

        // console.log("🚀 ~ file: ConciliacaoBancariaLancamentos.vue:550 ~ pagar ~ resposta:", resposta)
        if (resposta) {
          if (!!resposta.data.result && item.empreendcobr_tipo === 'P') {
            this.dialog_lancamentos_pagar = resposta.data.result.rows[0];
            this.dialog_lancamentos_pagar.devedor = this.dialog_lancamentos_pagar.cobr_dev.pessoa_nome
            this.dialog_lancamentos_pagar.pagador = this.dialog_lancamentos_pagar.devedor
          } 
          else if (!!resposta.data.result && item.empreendcobr_tipo === 'R') {
            this.dialog_lancamentos_receber = resposta.data.result;
            // console.log("🚀 ~ file: ConciliacaoBancariaLancamentos.vue:594 ~ pagar ~ this.dialog_lancamentos_receber:", this.dialog_lancamentos_receber)
            // this.dialog_lancamentos_pagar.devedor = this.dialog_lancamentos_pagar.cobr_dev.pessoa_nome
            // this.dialog_lancamentos_pagar.pagador = this.dialog_lancamentos_pagar.devedor
          } 
        }
      },

      // Cadastra Novo lançamento de contas a pagar/receber utilizando mesmo compontente que o consulta que o contas a pagar usa ou que o contas a receber usa
      async lancamentos_novo(tipo) {
        this.dados_lancamentos_editar                                     = [];
        this.dados_lancamentos_editar.empreend_venda                      = {};
        this.dados_lancamentos_editar.empreend_venda.vendas               = {};
        this.dados_lancamentos_editar.empreend_venda.pessoa_venda         = {};
        this.dados_lancamentos_editar.empreend_venda.empreendvenda_imovel = {};
        this.dados_lancamentos_editar.acao                                = 'novo'
        tipo == 'C' ? this.dialog_ContasReceberNovoModal = true : null;
        tipo == 'D' ? this.dialog_ContasPagarNovoModal   = true : null;
      },

      // Consulta lançamento de contas a pagar/receber através do componente Modal de Consulta de Cobranças
      async lancamentos_conciliar() {
        const ls_ArrayCodEmpreendCobranca = this.dialog_lancamentos.extrato_mov_conc.map(o => { return o.cod_empreendcobranca}).join(',')
        const lo_OBJ = {cod_extrato_movimento   : this.dialog_lancamentos.cod_extrato_movimento,
                        cod_empreendcobrancas   : ls_ArrayCodEmpreendCobranca
        }

        const ls_Res = await API.post('/erp/financeiro/conciliacao/lancamentos_conciliar', lo_OBJ);
        if (ls_Res.status == 200) {
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = ls_Res.data.result.result.trim();
          this.store_site.alert           = true;
          this.$emit('atualiza_extrato');
        }
        else {
          this.store_site.alert_cor       = "#FF0000";
          this.store_site.alert_timeout   = 10000;
          this.store_site.alert_msg       = ls_Res.data.errors.trim();
          this.store_site.alert           = true;
        }
      },

      // Função que recebe alterações do filho ContasPagarModal.vue para atualizar a grade
      async lancamentos_atualiza_registro(p_JSON) {
       let lo_Index = await this.dialog_lancamentos.extrato_mov_conc.findIndex((val) => val.cod_empreendcobranca == p_JSON.cod_empreendcobranca);
        console.log("🚀 ~ file: ConciliacaoBancariaLancamentos.vue:661 ~ lancamentos_atualiza_registro ~ lo_Index:", lo_Index)
        this.$set(this.dialog_lancamentos.extrato_mov_conc, lo_Index, p_JSON)
      },

      // Excluir pagamento dos lançamentos selecionados
      // async lancamentos_excluir(cod_) {
      //   const ls_Res = await API.post('/erp/financeiro/conciliacao/estornar_conciliacao', {cod_extrato_movimento: val});
      //   if (ls_Res.status == 200) {
      //     this.busca_extratos(this.cedente_selecionado.cod_pessoa_conta, formatDate(this.date1), formatDate(this.date2));
      //     this.store_site.alert_cor       = "#00A000";
      //     this.store_site.alert_timeout   = 1500;
      //     this.store_site.alert_msg       = ls_Res.data.result.result.trim();
      //     this.store_site.alert           = true;
      //   } else {
      //     this.store_site.alert_cor       = "#FF0000";
      //     this.store_site.alert_timeout   = 10000;
      //     this.store_site.alert_msg       = ls_Res.data.errors.trim();
      //     this.store_site.alert           = true;
      //   }
      //   this.dialog_estornar  = null;
      // }  

     
    },
  
  };
  </script>
    
  <style lang="scss" scoped>
  #ConciliacaoBancariaLancamentos {
    // height: 90vh;
    overflow-y: hidden;
  }
  /* ---------- BARRA DE SCROLL ---------- */
  .card::-webkit-scrollbar {
    width: 5px;
  }
  .card-list::-webkit-scrollbar {
    width: 5px;
  }
  .scroll-direita::-webkit-scrollbar {
    width: 5px;
  }
    .card::-webkit-scrollbar-button {
    padding: 1px;
  }
  .card-list::-webkit-scrollbar-button {
    padding: 1px;
  }
  .scroll-direita::-webkit-scrollbar-button {
    padding: 1px;
  }
  .card::-webkit-scrollbar-thumb {
    background: #CECECE;
    border-radius: 50px;
  }
  .card-list::-webkit-scrollbar-thumb {
    background: #CECECE;
    border-radius: 50px;
  }
  .scroll-direita::-webkit-scrollbar-thumb {
    background: #CECECE;
    border-radius: 50px;
  }
  .card::-webkit-scrollbar-thumb:hover {
    background: #A7A7A7;
    height: 10px;
  }
  .card-list::-webkit-scrollbar-thumb:hover {
    background: #A7A7A7;
    height: 10px;
  }
  .scroll-direita::-webkit-scrollbar-thumb:hover {
    background: #A7A7A7;
    height: 10px;
  }

  .scroll {
   overflow-y: scroll
  }

  .title-page {
    width: 600px !important;
    height: 80px;
    font-family: "Open Sans", sans-serif !important;
    font-size: 17px !important;
  }
  
  @media(max-width: 599px) {
    .title-page {
      width: 100% !important;
    }
  
    .text-opacity {
      opacity: 0.7;
    }
  }
  
  @media(max-width: 375px) {
    .tab-mobile {
      margin-left: -44px;
    }
  }
  
  .text-white {
    color: #FFFFFF;
    letter-spacing: 0px;
  }
  
  .btn-icon:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  
  .btn-icon:hover .icon-action {
    color: #FFF !important;
    opacity: 0.8;
  }
  
  .nav-link {
    text-decoration: none;
  }  
  .divisor-horizontal {
    // display: none;
    width: 100% !important;
    position: relative;
  }
  
  .divisor-vertical {
    // display: none;
    height: 100% !important;
    position: relative
  }
  
  .size-icon-expanded {
    font-size: 15px;
  }

  .list {
    background-color: transparent;
    min-width: 220px;
    margin-top: -2px;
    color: var(--COR_SUBTITULO);
    height: calc(100vh - 275px) !important;
  }
  .bg-active {
    background-color: var(--COR_PRINCIPAL);
    color: white !important;
  }
  
  .corpo-fundo-cor {
    background-color: var(--COR_BACKGROUND) !important;
  }

  // Muda a cor de borda do outlined
  ::v-deep .v-input--is-focused .v-input__slot fieldset   {
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
  }

  ::v-deep .background-hover::before {
    background-color: var(--COR_PRINCIPAL) !important;
    border: 1px solid black;
  }

  ::v-deep .outlined-btn {
    border: 1px solid transparent;
  }
  
  .status_aberto_vencer {
    font-size: 12px !important;
    /* background: #6C87A2; */
    background: rgb(242, 192, 98);
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }
  .status_aberto_vencida {
    font-size: 12px !important;
    /* background: #6C87A2; */
    background: #FF5252;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }  

  .status_pago {
    font-size: 12px !important;
    background: #469c47;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }

  .status_inativo {
    font-size: 12px !important;
    background: #525252;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }

  .status_rescindido {
    font-size: 12px !important;
    background: #A2A2A2;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }
  .title-page {
    font-family: "Open Sans", sans-serif!important;
    font-size: 19px!important;
    color: #FFFFFF;
    letter-spacing: 0px;
  }

  .title-page-menor {
    font-family: "Open Sans", sans-serif!important;
    font-size: 16px!important;
    color: #FFFFFF;
    letter-spacing: 0px;
  }


</style>
  